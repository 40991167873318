








































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import marked from "marked";

export default Vue.extend({
  props: {
    project: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      videoPlayer: false,
    };
  },
  computed: {
    ...mapGetters(["skillCategories", "skillKeys"]),
    period() {
      const [from, to] = this.project.period;
      const format = (x: string) => x;
      return `${format(from)} - ${format(to)}`;
    },
    duration() {
      const [from, to] = this.project.period;
      const toDate = (x: any) =>
        moment(`${x.substring(3, 7)}-${x.substring(0, 2)}-01`);
      return toDate(to).diff(toDate(from), "months") + 1;
    },
    skills() {
      const project = this.project;
      const skills = [];
      for (const key of this.skillKeys
        .slice()
        .sort((a: string, b: string) => a.localeCompare(b))
        .filter((x: string) => project[x])) {
        const values = !Array.isArray(project[key])
          ? [project[key]]
          : project[key];
        skills.push(
          ...values.map((x: string) => ({
            title: x,
            category: "coding",
          }))
        );
      }
      return skills;
    },
    image() {
      const client = this.project.client.toLowerCase();
      return client.includes("lufthansa")
        ? "lufthansa-logo.svg"
        : client.includes("condor")
        ? "condor-logo.png"
        : client.includes("thomas")
        ? "thomas-cook-logo.png"
        : client.includes("dkv")
        ? "dkv-logo.png"
        : client.includes("talea")
        ? "talea-logo.png"
        : "inocon-logo.svg";
    },
    color() {
      const client = this.project.client.toLowerCase();
      return client.includes("lufthansa")
        ? "#385F73"
        : client.includes("condor")
        ? "#263238"
        : client.includes("thomas")
        ? "#952175" //"#1F7087" //"#424242"
        : null;
    },
    playerOptions() {
      return {
        height: "450",
        sources: [
          {
            type: "video/mp4",
            src: `/media/${this.project.screencast}.mp4`,
          },
        ],
        autoplay: false,
        controls: true,
        poster: `/media/${this.project.screencast}.jpg`,
      };
    },
  },
  methods: {
    onSkillSelect(skill: string) {
      const category = this.skillCategories[skill];
      this.$store.dispatch("setFilter", { [category]: [skill] });
    },
    md2html(value: string) {
      return marked(value);
    },
  },
});
