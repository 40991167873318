






























































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { Skill } from "@/store/types";
import SkillRating from "./SkillRating.vue";

export default Vue.extend({
  components: {
    SkillRating
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["skillProjects", "skillCategories", "extendedSkills"]),
    filteredSkills() {
      return this.extendedSkills.filter((x: Skill) => x.icon);
    }
  },
  methods: {
    projectCount(skill: string): number {
      const projects: any[] = this.skillProjects[skill];
      if (!projects) return 0;
      else return projects.length;
    },
    sortSkills(values: any[]): any[] {
      return values.slice().sort((a, b) => {
        let cmp =
          (this as any).projectCount(b.title) -
          (this as any).projectCount(a.title);
        if (cmp != 0) return cmp;
        cmp = b.rating - a.rating;
        if (cmp != 0) return cmp;
        return a.title.localeCompare(b.title);
      });
    },
    onSelect(skill: string, category: string): void {
      if ((this as any).projectCount(skill) > 0) {
        this.$store.dispatch("setFilter", { [category]: [skill] });
        this.$router.push("/projects");
      }
    }
  }
});
