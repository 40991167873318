
































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import ProjectCard from "@/views/Projects/ProjectCard.vue";

export default Vue.extend({
  components: {
    ProjectCard
  },
  data: () => ({}),
  computed: {
    ...mapState(["projects"]),
    ...mapGetters(["filteredProjects"]),
    sortedProjects() {
      return this.filteredProjects;
    },
    filterCount() {
      return this.filteredProjects.length;
    },
    totalCount() {
      return this.projects.length;
    }
  },
  mounted() {
    const { code } = this.$route.params;
    if (code) {
      this.$store.dispatch("setPredefinedFilter", code);
    } else if (this.$route.query) {
      const q = this.$route.query;
      const filter = Object.keys(q).reduce((acc: any, key: string) => {
        acc[key] = `${q[key]}`.split(",");
        return acc;
      }, {});
      this.$store.dispatch("updateFilter", filter);
    }
  },
  methods: {
    download() {
      this.$store.dispatch("exportProjects", this.sortedProjects);
    }
  }
});
