









































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data: () => ({}),
  computed: {
    ...mapState(["personalData", "education", "skills"])
  }
});
