





















import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  data: () => ({}),
  props: {
    title: {
      type: String
    },
    rating: { type: Number }
  },
  computed: {
    ...mapGetters(["skillProjects"]),
    projectCount() {
      const projects: any[] = this.skillProjects[this.title];
      if (!projects) return 0;
      else return projects.length;
    }
  }
});
